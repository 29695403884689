import { useNavigate } from '@solidjs/router';
import { Show } from 'solid-js';
import IconBathroom from '~/assets/images/home/bathroom.svg';
import IconArea from '~/assets/images/home/ft.svg';
import IconLike from '~/assets/images/home/like.svg';
import IconRoom from '~/assets/images/home/room.svg';
import IconUnLike from '~/assets/images/home/unLike.svg';
import { FT, PINNED } from '~/assets/strings';
import { IconDefaultUnit } from '~/components/ui/Icons';
import useUseCase from '~/framework/hooks/useUseCase';
import { PresentableUnit } from '~/types/Unit';
import { SetLikedUseCase } from '~/use-cases/company-unit-listing/setLikedUseCase';
export interface PropertyItemInfo {
  id: string;
  image: string;
  title: string;
  description: string;
  price: string;
  amount: string;
  rooms: string;
  bathrooms: string;
  area: string;
  tags: string[];
  isLiked: boolean;
  isCollectioned: boolean;
  manager: string;
}

interface PropertyItemProps {
  data: PresentableUnit;
}

export const PropertyItem = (props: PropertyItemProps) => {
  const navigate = useNavigate();

  const { execute } = useUseCase(SetLikedUseCase);

  const handleLike = (unitId: string, isLiked: boolean, e: MouseEvent) => {
    e.stopPropagation();
    execute({
      unitId,
      isLiked,
    });
  };

  const renderTag = () => {
    return (
      <Show when={props.data.pinned}>
        <div class="flex h-[26px]  items-center justify-center rounded-[46px]  bg-danger px-[10px] py-1 text-xs text-white ">{PINNED}</div>
      </Show>
    );
  };

  return (
    <div
      class="w-full cursor-pointer overflow-hidden rounded-lg shadow-[0px_12px_36px_0px_rgba(59,77,129,0.08)] transition-all duration-300 hover:shadow-[rgba(59,77,129,0.2)]"
      id="property-item"
      onClick={() => navigate(`/properties/${props.data.id}`)}>
      <Show
        when={props.data.image}
        fallback={
          <div class="relative flex h-[240px] gap-[6px] bg-gradient-color-start bg-center bg-no-repeat p-5">
            <IconDefaultUnit color="var(--gradient-color-end)" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
            {renderTag()}
          </div>
        }>
        <div
          class="relative flex h-[240px] gap-[6px] bg-cover bg-center bg-no-repeat p-5"
          style={{ 'background-image': `url(${props.data.image})` }}>
          {renderTag()}
        </div>
      </Show>

      <div class="p-5">
        <div class="flex items-center justify-between">
          <div class="text-base font-medium text-[#363A3D]">{props.data.name}</div>
          <img
            class="w-[22px] cursor-pointer"
            id="property-item-like-icon"
            onClick={(e) => handleLike(props.data.id, props.data.isLiked, e)}
            src={props.data.isLiked ? IconLike : IconUnLike}
            alt=""
          />
        </div>
        <div class="text-sm text-text-level03">{props.data.address}</div>
        <div class="mt-3 text-[24px]  font-semibold text-primary-color">{props.data.price}</div>
        <div class="mt-5 flex gap-6 text-base text-text-level03">
          <div class="flex items-center gap-1">
            <img src={IconRoom} alt="" /> {props.data.bedrooms}
          </div>
          <div class="flex items-center gap-1">
            <img src={IconBathroom} alt="" /> {props.data.bathrooms}
          </div>
          <div class="flex items-center gap-1">
            <img src={IconArea} alt="" /> {props.data.area} {FT}
          </div>
        </div>
      </div>
    </div>
  );
};
